import React, { FunctionComponent } from 'react'
import { Box, Typography } from '@material-ui/core'
const RightPanelError: FunctionComponent<{ submitError?: string }> = props => (
  <Box boxShadow={2}>
    <Box padding={1} bgcolor={'error.main'}>
      <Typography variant={'h4'} style={{ color: 'white' }}>
        {props.submitError || 'We had a problem'}
      </Typography>
    </Box>
    <Box padding={2}>
      {props.submitError &&
      props.submitError.startsWith('Your card was declined') ? (
        <Typography>
          Could you look at the details and try again please?
        </Typography>
      ) : (
        <>
          <Typography>It looks like we had an error on our side.</Typography>
          <Typography>Don't worry, you have not been charged!</Typography>
          <Typography>
            Someone from the team will look into it and get in touch within a
            day
          </Typography>
        </>
      )}
    </Box>
  </Box>
)
export default RightPanelError
